















































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class BottomBar extends Vue {
  navDrawer = null;

  socialLinks = [
    {
      icon: "mdi-twitter",
      href: "https://twitter.com/kahev2018"
    },
    {
      icon: "mdi-facebook",
      href: "http://fb.me/kahev2018"
    },
    {
      icon: "mdi-youtube",
      href: "https://www.youtube.com/c/kahev2018"
    },
    {
      icon: "mdi-instagram",
      href: "https://www.instagram.com/kahev.akademi"
    }
  ];

  menuItems = [
    {
      title: "Mesafeli Satış Sözleşmesi",
      to: { name: "distance-sales-contract" }
    },
    {
      title: "Kullanım Şartları",
      to: { name: "terms-of-use" }
    },
    {
      title: "Gizlilik Sözleşmesi",
      to: { name: "privacy-policy" }
    },
    {
      title: "Kişisel Verilerin Korunması",
      to: { name: "personal-data-protection" }
    }
  ];
}
